// Generated by Framer (50a537b)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-MZScT"

const variantClassNames = {pEg1PhfNm: "framer-v-1robtxm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "pEg1PhfNm", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.nav {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1robtxm", className, classNames)} data-border data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"pEg1PhfNm"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(0, 0, 0, 0.08)", "--border-left-width": "0px", "--border-right-width": "0px", "--border-style": "solid", "--border-top-width": "0px", backgroundColor: "rgb(255, 255, 255)", ...style}}><motion.div className={"framer-huh8ew"} data-framer-name={"Gradient Left"} layoutDependency={layoutDependency} layoutId={"yyKA_vgrN"} style={{background: "linear-gradient(270deg, hsla(0, 0%, 100%, 0) 0%, hsl(0, 0%, 100%) 100%)"}}/><motion.div className={"framer-e9vhim"} data-framer-name={"Gradient Right"} layoutDependency={layoutDependency} layoutId={"SaQ6RAe25"} style={{background: "linear-gradient(90deg, hsla(0, 0%, 100%, 0) 0%, hsl(0, 0%, 100%) 100%)"}}/></motion.nav></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MZScT.framer-kc4bgu, .framer-MZScT .framer-kc4bgu { display: block; }", ".framer-MZScT.framer-1robtxm { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 128px; justify-content: flex-start; overflow: auto; padding: 0px; position: relative; width: 1200px; }", ".framer-MZScT .framer-huh8ew { bottom: 1px; flex: none; left: 0px; overflow: visible; pointer-events: none; position: absolute; top: 64px; width: 20px; z-index: 1; }", ".framer-MZScT .framer-e9vhim { bottom: 1px; flex: none; overflow: visible; pointer-events: none; position: absolute; right: 0px; top: 64px; width: 60px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-MZScT.framer-1robtxm { gap: 0px; } .framer-MZScT.framer-1robtxm > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-MZScT.framer-1robtxm > :first-child { margin-top: 0px; } .framer-MZScT.framer-1robtxm > :last-child { margin-bottom: 0px; } }", ".framer-MZScT[data-border=\"true\"]::after, .framer-MZScT [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 128
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerbZVdVKRlS: React.ComponentType<Props> = withCSS(Component, css, "framer-MZScT") as typeof Component;
export default FramerbZVdVKRlS;

FramerbZVdVKRlS.displayName = "Navigation";

FramerbZVdVKRlS.defaultProps = {height: 128, width: 1200};

addFonts(FramerbZVdVKRlS, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})